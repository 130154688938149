// @vue/component
export default {
	components: {
		PanelAnnounceVideo: () => import('~/components/panels/PanelAnnounceVideo.vue'),
		PanelCenterCards: () => import('~/components/panels/card/PanelCenterCards.vue'),
		PanelComponentBlock: () => import('~/components/panels/PanelComponentBlock.vue'),
		PanelConferenceAbout: () => import('~/components/panels/PanelConferenceAbout.vue'), // пнель о конференции
		PanelConferenceCharacteristics: () => import('~/components/panels/PanelConferenceCharacteristics.vue'), // характеристики конференции
		PanelConferenceScheduleThree: () => import('~/components/panels/PanelConferenceScheduleThree.vue'), // расписание, три колонки
		PanelConferenceScheduleTwo: () => import('~/components/panels/PanelConferenceScheduleTwo.vue'), // расписание, две колонки
		PanelConferenceSpeakers: () => import('~/components/panels/list/PanelConferenceSpeakers.vue'), // панель спикеров
		PanelConferenceSpeakersSlider: () => import('~/components/panels/list/PanelConferenceSpeakersSlider.vue'),
		PanelConsultants: () => import('~/components/panels/list/PanelConsultants.vue'),

		PanelDiscourseCharacteristics: () => import('~/components/panels/PanelDiscourseCharacteristics.vue'), // основные характеристики диалога
		PanelDiscoursePoll: () => import('~/components/panels/PanelDiscoursePoll.vue'), // панель опроса
		PanelDiscourseTalkThemes: () => import('~/components/panels/PanelDiscourseTalkThemes.vue'), // о чем говорят
		PanelDonationAbout: () => import('~/components/panels/PanelDonationAbout.vue'),
		PanelDonationProjects: () => import('~/components/panels/PanelDonationProjects.vue'),
		PanelDonationSupportedBy: () => import('~/components/panels/PanelDonationSupportedBy.vue'),
		PanelDonationWhatToDo: () => import('~/components/static/PanelDonationWhatToDo.vue'),
		PanelDonationWhatWeDo: () => import('~/components/panels/PanelDonationWhatWeDo.vue'),
		PanelDynamicEvents: () => import('~/components/panels/PanelDynamicEvents.vue'),
		PanelDynamicEventsAnnounces: () => import('~/components/panels/PanelDynamicEventsAnnounces.vue'),
		PanelDynamicCalendarEvents: () => import('~/components/panels/PanelDynamicCalendarEvents.vue'),
		PanelDynamicUsefulContent: () => import('~/components/panels/PanelDynamicUsefulContent.vue'),
		PanelDynamicUsefulContentNoTabs: () => import('~/components/panels/PanelDynamicUsefulContentNoTabs.vue'),
		PanelDynamicMedias: () => import('~/components/panels/PanelDynamicMedias.vue'),
		PanelDynamicMediasTabs: () => import('~/components/panels/PanelDynamicMediasTabs.vue'),
		PanelDynamicMediasTabsWithPagination: () => import('~/components/panels/PanelDynamicMediasTabsPagination.vue'),
		PanelGeneralAccordion: () => import('~/components/panels/PanelGeneralAccordion.vue'),
		PanelInstagram: () => import('~/components/panels/PanelInstagram.vue'),
		PanelMedia: () => import('~/components/panels/PanelMedia.vue'),
		PanelNavigation: () => import('~/components/panels/system/PanelNavigation.vue'), // общий блок навигации
		AnchorPanel: () => import('~/components/panels/system/PanelAnchor.vue'),
		PanelPartnersDynamic: () => import('~/components/panels/partner/PanelPartnersDynamic.vue'),
		PanelPartnersWithDescription: () => import('~/components/panels/partner/PanelPartnersWithDescription.vue'), // партнеры с описанием
		PanelPlaylistYoutube: () => import('~/components/panels/list/PanelPlaylistYoutube.vue'), // панель вывода ютуб плейлиста
		PanelPlaylistYoutubeUnpublish: () => import('~/components/panels/list/PanelPlaylistYoutubeUnpublish.vue'), // панель вывода ютуб плейлиста
		PanelProgramAudience: () => import('~/components/panels/PanelProgramAudience.vue'), // панель в программах, аудитория
		PanelProgramCharacteristics: () => import('~/components/panels/PanelProgramCharacteristics.vue'), // характеристики программы
		PanelProgramGalleryFlickr: () => import('~/components/panels/list/PanelProgramGalleryFlickr.vue'), // панель фликра
		PanelProgramPaidProjects: () => import('~/components/panels/PanelProgramPaidProjects.vue'),
		PanelProgramPrice: () => import('~/components/panels/price/PanelProgramPrice.vue'), // панели с блоками стоимости
		PanelPrice01: () => import('~/components/panels/price/PanelPrice01.vue'), // панели с блоками стоимости
		PanelProgramSchedule: () => import('~/components/panels/schedule/PanelProgramSchedule.vue'), // панель расписания + расхлоп
		PanelProgramSteps: () => import('~/components/panels/PanelProgramSteps.vue'), // панель шагов в программахх
		PanelResearchReview: () => import('~/components/panels/research/PanelResearchReview.vue'),
		PanelSectionPeople: () => import('~/components/panels/PanelSectionPeople.vue'),

		PanelSmartForm: () => import('~/components/panels/PanelSmartForm.vue'), // умная форма
		PanelCheckoutForm: () => import('~/components/panels/PanelCheckoutForm.vue'), // форма оформления заказа
		PanelStaticProgram: () => import('~/components/panels/PanelStaticProgram.vue'),
		PanelTabsStaticProgram: () => import('~/components/panels/PanelTab.vue'),
		PanelSubscribeShort: () => import('~/components/panels/PanelSubscribeShort.vue'),
		PanelSubscribePlans: () => import('~/components/panels/price/PanelSubscribePlans.vue'),

		PanelResearchWoman: () => import('~/components/panels/research/PanelResearchWoman.vue'),
		PanelResearchOneChart: () => import('~/components/panels/research/PanelResearchOneChart.vue'),
		PanelResearchMan: () => import('~/components/panels/research/PanelResearchMan.vue'),
		PanelResearchInformation: () => import('~/components/panels/research/PanelResearchInformation.vue'),
		PanelResearchCircles: () => import('~/components/panels/research/PanelResearchCircles.vue'),
		PanelResearchSimple: () => import('~/components/panels/research/PanelResearchSimple.vue'),
		PanelResearchAbout: () => import('~/components/panels/research/PanelResearchAbout.vue'),
		PanelResearchFooter: () => import('~/components/panels/research/PanelResearchFooter.vue'),
		PanelResearchTwoCharts: () => import('~/components/panels/research/PanelResearchTwoCharts.vue'),

		PanelTe01: () => import('~/components/panels/TE/PanelTe01.vue'),
		PanelTe03: () => import('~/components/panels/TE/PanelTe03.vue'),
		PanelTe04: () => import('~/components/panels/TE/PanelTe04.vue'),
		PanelConferenceThemes: () => import('~/components/panels/TE/PanelConferenceThemes.vue'), // панель темы конференций
		PanelContentWithSidebar: () => import('~/components/panels/TE/PanelContentWithSidebar.vue'), // панель темы конференций
		PanelSimilarColumns: () => import('~/components/panels/TE/PanelSimilarColumns.vue'),

		PanelLi01: () => import('~/components/panels/LI/PanelLi01.vue'),
		PanelLi02: () => import('~/components/panels/LI/PanelLi02.vue'),

		PanelTimeline: () => import('~/components/panels/advantage/PanelTimeline.vue'),
		PanelAdvantage01: () => import('~/components/panels/advantage/PanelAdvantage01.vue'),

		PanelCardsWide: () => import('~/components/panels/card/PanelCardsWide.vue'),
		PanelCardsOnBackground: () => import('~/components/panels/card/PanelCardsOnBackground.vue'),

		PanelCardsHeighten: () => import('~/components/panels/card/PanelCardsHeighten.vue'),
		PanelCard02: () => import('~/components/panels/card/PanelCard02.vue'),
		PanelReview01: () => import('~/components/panels/review/PanelReview01.vue'),

		AboutIssue: () => import('~/components/refactor/AboutIssue.vue'),
		GraduateTips: () => import('~/components/refactor/GraduateTips.vue'),

		PanelQna: () => import('~/components/panels/PanelQna.vue'),
		Partners: () => import('~/components/panels/partner/Partners.vue'),
		VideoEmbed: () => import('~/components/refactor/VideoEmbed.vue'),
		Wysiwyg: () => import('~/components/refactor/Wysiwyg.vue'),
		WysiwygWithEntities: () => import('~/components/refactor/WysiwygWithEntities.vue'),
		Gallery: () => import('~/components/refactor/Gallery.vue'),

		PanelHotelBooking: () => import('~/components/panels/PanelHotelBooking/PanelHotelBooking.vue'),
		PanelChat: () => import('~/components/panels/PanelChat.vue'),
		PanelVideoChat: () => import('~/components/panels/form/PanelVideoChat.vue'),
		PanelRunningResults: () => import('~/components/panels/PanelRunningResults.vue'),

		PanelHe01: () => import('~/components/panels/HE/PanelHe01.vue'),
		PanelHe02: () => import('~/components/panels/HE/PanelHe02.vue'),
		PanelHe03: () => import('~/components/panels/HE/PanelHe03.vue'),
		PanelHe04: () => import('~/components/panels/HE/PanelHe04.vue'),
		PanelHe05: () => import('~/components/panels/HE/PanelHe05.vue'),

		PanelTi04: () => import('~/components/panels/TI/PanelTi04.vue'),

		PanelKp01: () => import('~/components/panels/KP/PanelKp01.vue'),
		PanelKp02: () => import('~/components/panels/KP/PanelKp02.vue'),
		PanelKp03: () => import('~/components/panels/KP/PanelKp03.vue'),
		PanelKp04: () => import('~/components/panels/KP/PanelKp04.vue'),
		PanelKp05: () => import('~/components/panels/KP/PanelKp05.vue'),

		PanelAb01: () => import('~/components/panels/AB/PanelAb01.vue'),
		PanelAb02: () => import('~/components/panels/AB/PanelAb02.vue'),
		PanelAb03: () => import('~/components/panels/AB/PanelAb03.vue'),
		PanelAb04: () => import('~/components/panels/AB/PanelAb04.vue'),
		PanelAb05: () => import('~/components/panels/AB/PanelAb05.vue'),

		PanelWh01: () => import('~/components/panels/WH/PanelWh01.vue'),
		PanelWh02: () => import('~/components/panels/WH/PanelWh02.vue'),
		PanelWh04: () => import('~/components/panels/WH/PanelWh04.vue'),
		PanelWh05: () => import('~/components/panels/WH/PanelWh05.vue'),
		PanelWh06: () => import('~/components/panels/WH/PanelWh06.vue'),

		PanelSt01: () => import('~/components/panels/ST/PanelSt01.vue'),
		PanelSt02: () => import('~/components/panels/ST/PanelSt02.vue'),
		PanelSt03: () => import('~/components/panels/ST/PanelSt03.vue'),
		PanelSt04: () => import('~/components/panels/ST/PanelSt04.vue'),
		PanelSt05: () => import('~/components/panels/ST/PanelSt05.vue'),
		PanelSt06: () => import('~/components/panels/ST/PanelSt06.vue'),
		PanelSt07: () => import('~/components/panels/ST/PanelSt07.vue'),

		PanelDr01: () => import('~/components/panels/DR/PanelDr01.vue'),
		PanelDr02: () => import('~/components/panels/DR/PanelDr02.vue'),
		PanelDr03: () => import('~/components/panels/DR/PanelDr03.vue'),
		PanelDr04: () => import('~/components/panels/DR/PanelDr04.vue'),
		PanelDr05: () => import('~/components/panels/DR/PanelDr05.vue'),
		PanelDr06: () => import('~/components/panels/DR/PanelDr06.vue'),

		PanelRe01: () => import('~/components/panels/RE/PanelRe01.vue'),

		PanelCa01: () => import('~/components/panels/CA/PanelCa01.vue'),
		PanelCa02: () => import('~/components/panels/CA/PanelCa02.vue'),
		PanelCa03: () => import('~/components/panels/CA/PanelCa03.vue'),
		PanelCa04: () => import('~/components/panels/CA/PanelCa04.vue'),
		PanelCa06: () => import('~/components/panels/CA/PanelCa06.vue'),
		PanelCa07: () => import('~/components/panels/CA/PanelCa07.vue'),

		PanelSc01: () => import('~/components/panels/SC/PanelSc01.vue'),
		PanelSc01speak: () => import('~/components/panels/SC/PanelSc01Speak.vue'),

		PanelTr01: () => import('~/components/panels/TR/PanelTr01.vue'),

		PanelSn01: () => import('~/components/panels/SN/PanelSn01.vue'),

		PanelKpcw01: () => import('~/components/blocks/KPContentWidth/PanelKp01fw.vue'),
		PanelKpcw02: () => import('~/components/blocks/KPContentWidth/PanelKp02fw.vue'),
		PanelKpcw03: () => import('~/components/blocks/KPContentWidth/PanelKp03fw.vue'),
		PanelKpcw04: () => import('~/components/blocks/KPContentWidth/PanelKp04fw.vue'),
		PanelKpcw05: () => import('~/components/blocks/KPContentWidth/PanelKp05fw.vue'),

		PanelIframe01: () => import('~/components/panels/IFRAME/PanelIframe01.vue'),

		PanelForm01: () => import('~/components/panels/FORM/PanelForm01.vue'),
		PanelFormTravelline: () => import('~/components/panels/FORM/PanelFormTravelline.vue'),
		PanelFormSubscribe: () => import('~/components/panels/FORM/PanelFormSubscribe.vue'),

		PanelNav01: () => import('~/components/panels/NAV/PanelNav01.vue'),

		// ? После внесения в админку открыть здесь и удалить в \components\panels\PanelComponentBlock.vue ?
		// PanelNav02: () => import('~/components/panels/NAV/PanelNav02.vue'),

		PanelLessons: () => import('~/components/panels/PanelLessons.vue'),
		PanelAboutLesson: () => import('~/components/panels/AB/PanelAboutLesson.vue'),
		PanelLessonQuestions: () => import('~/components/panels/Lessons/PanelLessonQuestions.vue'),
		PanelAboutCourse: () => import('~/components/panels/AB/PanelAboutCourse.vue'),
		PanelZoomConf: () => import('~/components/panels/TR/PanelZoomConf.vue'),

		PanelMainPartners: () => import('~/components/panels/ImAlexandra/PanelMainPartners.vue'),
		PanelSolutions: () => import('~/components/panels/ImAlexandra/PanelSolutions.vue'),
		PanelSpeakersNights: () => import('~/components/panels/ImAlexandra/PanelSpeakersNights.vue'),
		PanelSpeakersNights2: () => import('~/components/panels/ImAlexandra/PanelSpeakersNights2.vue'),
		PanelSpeakersNights3: () => import('~/components/panels/ImAlexandra/PanelSpeakersNights3.vue'),

		PanelPeredovitza: () => import('~/components/panels/list/PanelPeredovitza.vue'),
		PanelMediaSlider: () => import('~/components/panels/list/PanelMediaSlider.vue'),
		PanelCards: () => import('~/components/panels/card/PanelCards.vue'),

		PanelAdmissionStages: () => import('~/components/panels/Admission/PanelStages.vue'),
		PanelAdmissionStageForm: () => import('~/components/panels/Admission/PanelStageForm.vue'),
		PanelAdmissionStageTest: () => import('~/components/panels/Admission/PanelStageTest.vue'),
		PanelAdmissionStageVideoService: () => import('~/components/panels/Admission/PanelStageVideoService.vue'),
		PanelAdmissionApplication: () => import('~/components/panels/Admission/PanelAdmissionApplication.vue'),

		PanelDynamicEventsRss: () => import('~/components/panels/LI/PanelDynamicEventsRss.vue'),
		PanelDynamicMaterialsRss: () => import('~/components/panels/LI/PanelDynamicMaterialsRss.vue'),

		PanelTinkoffCredit: () => import('~/components/panels/static/PanelTinkoffCredit.vue'),
		PanelDonationsNumber: () => import('~/components/panels/PanelDonationsNumber.vue'),
		PanelCampusScheme: () => import('~/components/panels/static/PanelCampusScheme.vue'),
		PanelCarouselBlock: () => import('~/components/panels/LI/PanelCarousel.vue'),

		PanelAuthoredReview: () => import('~/components/panels/PanelMedia/PanelAuthoredReview.vue'),
		PanelRecommendedArticles: () => import('~/components/panels/PanelMedia/PanelRecommendedArticles.vue'),
		PanelGraduateTipsEvents: () => import('~/components/panels/PanelMedia/GraduateTipsEvents.vue'),
		PanelLoyaltyForm: () => import('~/components/panels/Loyalty/PanelLoyaltyForm.vue'),
		PanelLoyaltyHistory: () => import('~/components/panels/Loyalty/PanelLoyaltyHistory.vue'),
		PanelLoyaltyMorePoints: () => import('~/components/panels/Loyalty/PanelLoyaltyMorePoints.vue'),
		PanelLoyaltyUnaccountedActivities: () => import('~/components/panels/Loyalty/PanelLoyaltyUnaccountedActivities.vue'),
		PanelLoyalty: () => import('~/components/panels/Loyalty/PanelLoyalty.vue'),
		PanelLoyaltyB2b: () => import('~/components/panels/Loyalty/PanelLoyaltyB2b.vue'),
		PanelLoyaltyCalculator: () => import('~/components/panels/Loyalty/PanelLoyaltyCalculator.vue'),
		PanelLoyaltyPartnerPrivileges: () => import('~/components/panels/Loyalty/PanelLoyaltyPartnerPrivileges.vue'),
		PanelLoyaltyGeneratePromocode: () => import('~/components/panels/Loyalty/PanelLoyaltyGeneratePromocode.vue'),
		PanelLoyaltyRequestCertificate: () => import('~/components/panels/Loyalty/PanelLoyaltyRequestCertificate.vue'),
		PanelLoyaltyRequestConsultation: () => import('~/components/panels/Loyalty/PanelLoyaltyRequestConsultation.vue'),
		PanelFormulateForm: () => import('~/components/panels/PanelFormulateForm.vue'),
		PanelAddToCart: () => import('~/components/panels/PanelAddToCart.vue'),
		PanelLoyaltyPersonalBadge: () => import('~/components/panels/Loyalty/PanelLoyaltyPersonalBadge.vue'),
		PanelLoyaltyDonatePoints: () => import('~/components/panels/Loyalty/PanelLoyaltyDonatePoints.vue'),
		PanelLoyaltyGroupDonatePoints: () => import('~/components/panels/Loyalty/PanelLoyaltyGroupDonatePoints.vue'),
		PanelLoyaltyNpsRate: () => import('~/components/panels/Loyalty/PanelLoyaltyNpsRate.vue'),

		PanelFormulateProduct: () => import('~/components/panels/PanelFormulateProduct.vue'),
		PanelApprovedPresets: () => import('~/components/panels/PanelApprovedPresets.vue'),
		PanelAudioPlayer: () => import('~/components/panels/PanelAudioPlayer.vue'),

		PanelCardsOnBackgroundLoyalty: () => import('~/components/panels/PanelCardsOnBackgroundLoyalty.vue'),

		PanelAlumniUserEvent: () => import('~/components/panels/Alumni/PanelAlumniUserEvent.vue'),
		PanelSendAlumniRequest: () => import('~/components/panels/static/PanelSendAlumniRequest.vue'),
		PanelAlumniSpace: () => import('~/components/panels/Alumni/PanelAlumniSpace.vue'),
		PanelAlumniDirectory: () => import('~/components/panels/Alumni/PanelAlumniDirectory.vue'),
		PanelAlumniSettings: () => import('~/components/panels/Alumni/PanelAlumniSettings.vue'),
		PanelAlumniVerify: () => import('~/components/panels/Alumni/PanelAlumniVerify.vue'),

		PanelRecommendedPrograms: () => import('~/components/panels/list/PanelRecommendedPrograms.vue'),

		PanelUserRecommendations: () => import('~/components/panels/PanelRecommendations.vue'),

		PanelNavigatorMediaResearch: () => import('~/components/panels/navigator/PanelNavigatorMediaResearch.vue'),

		PanelSearchLine: () => import('~/components/panels/navigator/PanelSearchLine.vue'),
	},
};
